
  <div class="signup-bar">
    <div class="container">
      <div class="row">
        <div class="one column">
          <img src="../../assets/images/logo-footer.png" class="u-max-full-width"/>
        </div>
        <div class="six columns">
          <h6>Empower Your Before & After Photos with HumazeMD</h6>
        </div>
      </div>
    </div>
  </div>

  <div class="footer">
    <div class="container">
      <div class="row">
        <div class="two columns">
          <h6>About Us</h6>
          <ul>
            <li><a target="_blank" href="https://www.treehouse-holdings.com/">About</a></li>
            <li><a href="/contact">Contact</a></li>
            <li><a target="_blank" href="https://www.linkedin.com/jobs/search/?f_C=10890597&locationId=OTHERS.worldwide">Careers</a></li>
            <li><a target="_blank" href="https://humazemd.freshdesk.com/support/solutions/36000045574">FAQ</a></li>
          </ul>
        </div>
        <div class="two columns">
          <h6>More</h6>
          <ul>
            <li><a target="_blank" href="https://humazemd.freshdesk.com/support/home">Customer Care</a></li>
            <!-- <li><a href="#">Google App</a></li>
            <li><a href="#">iPhone App</a></li>
              These apps are not ready yet so keep the comment -->
            <!-- <li><a href="#">RSS</a></li>
              We are not sure if this is going to be used at all -->
            <li><a href="#" routerLink="/privacy">Privacy</a></li>
            <li><a href="#" routerLink="/terms-of-use">Terms of Use</a></li>
            <li><a href="#" routerLink="/legal">Legal</a></li>
          </ul>
        </div>
        <div class="two columns">
          <h6>Social</h6>
          <ul>
            <li><a target="_blank" href="https://www.facebook.com/HumazeMD/">Facebook</a></li>
            <li><a target="_blank" href="https://www.linkedin.com/company/10890597/">LinkedIn</a></li>
            <li><a target="_blank" href="https://www.pinterest.com/HumazeMD/">Pinterest</a></li>
            <li><a target="_blank" href="https://twitter.com/HumazeMD">Twitter</a></li>
            <li><a target="_blank" href="https://www.instagram.com/humazemd/">Instagram</a></li>
          </ul>
        </div>
        <div class="six columns">
          <div>
            <a target="_blank" href="https://www.facebook.com/HumazeMD/"><i class="ion-social-facebook"></i></a>
            <a target="_blank" href="https://www.linkedin.com/company/10890597/"><i class="ion-social-linkedin"></i></a>
            <a target="_blank" href="https://www.pinterest.com/HumazeMD/"><i class="ion-social-pinterest"></i></a>
            <a target="_blank" href="https://twitter.com/HumazeMD"><i class="ion-social-twitter"></i></a>
            <a target="_blank" href="https://www.instagram.com/humazemd/"><i class="ion-social-instagram"></i></a>
          </div>
          <p>© HumazeMD, 2022</p>
        </div>
      </div>
    </div>
  </div>
