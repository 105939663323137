import { AboutComponent } from './cases/feed/about/about.component';
import { AppComponent } from './app.component';
import { AppFooterComponent } from './app-footer/app-footer.component';
import { AppNavbarComponent } from './app-navbar/app-navbar.component';
import { AppRoutingModule } from './app-routing.module';
import { AdminService } from './admin/admin.service';
import { AdminCaseComponent } from './admin/admin-case/admin-case.component';
import { AdminCaseDetailsComponent } from './admin/admin-case/admin-case-details/admin-case-details.component';
import { AdminComponent } from './admin/admin.component';
import { AddUserComponent } from './admin/users/add-user/add-user.component';
import { AuthInterceptor} from './interceptors/http-interceptor.service';
import { AuthService } from './auth/auth.service';
import { BannerComponent } from './admin/news/news.component';
import { BrandsComponent } from './admin/brands/brands.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CasesComponent } from './cases/cases.component';
import { CaseService } from './cases/cases.service';
import { ChangePasswordPageComponent } from './home-page/change-password-page/change-password-page.component';
import { ClickOutsideModule } from 'ng4-click-outside';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { Constants } from './constants';
import { ContactPageComponent } from './home-page/contact-page/contact-page.component';
import { CreateEditBrandComponent } from './admin/brands/create-edit-brand/create-edit-brand.component';
import { CreateEditManufacturerComponent } from './admin/manufacturers/create-edit-manufacturer/create-edit-manufacturer.component';
import { CreateEditGenderComponent } from './admin/genders/create-edit-gender/create-edit-gender.component';
import { CreateEditEthnicityComponent } from './admin/ethnicities/create-edit-ethnicity/create-edit-ethnicity.component';
import { ChangeCreditCardComponent } from './user/change-credit-card/change-credit-card.component';
import { CreateEditTypeComponent } from './admin/skin-types/create-edit-type/create-edit-type.component';
import { CreateCaseComponent } from './cases/create-case/create-case.component';
import { DataService } from './helpers/data-service';
import { DatePipe } from '@angular/common';
import { EditImageComponent } from './shared/edit-image/edit-image.component';
import { EditUserComponent } from './admin/users/edit-user/edit-user.component';
import { EditCaseComponent } from './cases/edit-case/edit-case.component';
import { EditTreatmentComponent } from './admin/treatments/edit-treatment/edit-treatment.component';
import { EmbedPageComponent } from './embed-page/embed-page.component';
import { EthnicitiesComponent } from './admin/ethnicities/ethnicities.component';
import { FeedComponent } from './cases/feed/feed.component';
import { FeedDataService } from './cases/feed/feed-data.service';
import { ForgotPasswordPageComponent } from './home-page/forgot-password-page/forgot-password-page.component';
import { ForgotPasswordFormComponent } from './home-page/forgot-password-form/forgot-password-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GeneralFeedComponent } from './cases/feed/general-feed/general-feed.component';
import { GendersComponent } from './admin/genders/genders.component';
import { HomePageComponent } from './home-page/home-page.component';
import { HomePageService } from './home-page/home-page.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HeaderComponent } from './cases/feed/header/header.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LegalComponent } from './home-page/legal/legal.component';
import { LowerCaseUrlSerilizer } from './helpers/lower-case-url-serializer';
import { ManufacturersComponent } from './admin/manufacturers/manufacturers.component';
import { ModalModule} from 'ngx-bootstrap/modal/modal.module';
import { NewsComponent, AddNewsComponent, EditNewsComponent } from './admin/news/news.component';
import { NewsDataService } from './admin/news/news-data.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { OverallKpisComponent } from './admin/overall-kpis/overall-kpis.component';
import { PopoverModule } from 'ngx-bootstrap/popover/popover.module';
import { PrivacyPageComponent } from './home-page/privacy-page/privacy-page.component';
import { PressPageComponent } from './home-page/press-page/press-page.component';
import { ResetPasswordPageComponent } from './home-page/reset-password-page/reset-password-page.component';
import { RouterModule, Routes, UrlSerializer } from '@angular/router';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { SelectBrandsComponent } from './helpers/select-brands/select-brands.component';
import { SelectGenderComponent } from './helpers/select-gender/select-gender.component';
import { SelectTreatmentsComponent } from './helpers/select-treatments/select-treatments.component';
import { SelectSkinTypeComponent } from './helpers/select-skin-type/select-skin-type.component';
import { SignupComponent } from './auth/signup/signup.component';
import { SigninComponent } from './auth/signin/signin.component';
import { SingleFeedModalComponent } from './cases/feed/single-feed-modal/single-feed-modal.component';
import { SkinTypesComponent } from './admin/skin-types/skin-types.component';
import { TreatmentsComponent } from './admin/treatments/treatments.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TermsOfUseComponent } from './home-page/terms-of-use/terms-of-use.component';
import { TermsOfServiceComponent } from './home-page/terms-of-service/terms-of-service.component';
import { ToastrModule } from 'ngx-toastr';
import { UserEditPageComponent } from './user/user-edit-page/user-edit-page.component';
import { UserEmbedPageComponent } from './user/user-embed-page/user-embed-page.component';
import { UserService } from './user/user.service';
import { VisitorFeedComponent } from './cases/feed/visitor-feed/visitor-feed.component';
import { WatchTheVideoComponent } from './home-page/watch-the-video/watch-the-video.component';
import { UnauthorizedComponent } from './shared/unauthorized/unauthorized.component';
import { UsersComponent } from './admin/users/users.component';
import { UserOverallKpisComponent } from './admin/users/user-overall-kpis/user-overall-kpis.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import {DatepickerComponent} from './helpers/date-picker/date-picker.component';


const appRoutes: Routes = [
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: '', component: HomePageComponent},
  { path: 'signup', component: SignupComponent },
  { path: 'signin', component: SigninComponent },
  { path: 'signin/:redirect', component: SigninComponent },
  { path: 'signin/:redirect/:param', component: SigninComponent },
  { path: 'cases', component: CasesComponent },
  { path: 'feed',
    children: [
      { path: '', component: FeedComponent },
      { path: 'provider/:id', component: FeedComponent }
    ]
  },
  {
    path: 'hm',
      children: [
      { path: ':id', component: FeedComponent }
      ]
  },
  {
    path: 'embed',
    children: [
      { path: ':id', component: EmbedPageComponent },
      { path: '**', component: EmbedPageComponent }
    ]
  },
  { path: 'create', component: CreateCaseComponent },
  { path: 'admin', component: AdminComponent,
    children: [
      { path: 'news', component: NewsComponent },
      { path: 'news/add', component: AddNewsComponent },
      { path: 'news/edit', component: EditNewsComponent },
      { path: 'news/banner', component: BannerComponent },
      { path: 'cases', component: AdminCaseComponent },
      { path: 'cases/details/:id', component: AdminCaseDetailsComponent },
      { path: 'users', component: UsersComponent },
      { path: 'user/:id/edit', component: EditUserComponent },
      { path: 'user/add', component: AddUserComponent },
      { path: 'treatments', component: TreatmentsComponent },
      { path: 'treatment', component: EditTreatmentComponent },
      { path: 'treatment/:id', component: EditTreatmentComponent },
      { path: 'brands', component: BrandsComponent },
      { path: 'brand', component: CreateEditBrandComponent },
      { path: 'brand/:id/edit', component: CreateEditBrandComponent },
      { path: 'manufacturers', component: ManufacturersComponent },
      { path: 'manufacturer/:id/edit', component: CreateEditManufacturerComponent },
      { path: 'manufacturer', component: CreateEditManufacturerComponent },
      { path: 'genders', component: GendersComponent },
      { path: 'gender/:id/edit', component: CreateEditGenderComponent },
      { path: 'gender', component: CreateEditGenderComponent },
      { path: 'skin-types', component: SkinTypesComponent },
      { path: 'skin-type/:id/edit', component: CreateEditTypeComponent },
      { path: 'skin-type', component: CreateEditTypeComponent },
      { path: 'ethnicities', component: EthnicitiesComponent },
      { path: 'ethnicity', component: CreateEditEthnicityComponent },
      { path: 'ethnicity/:id', component: CreateEditEthnicityComponent },
      { path: 'overall-kpis', component: OverallKpisComponent },
      { path: 'user_overall_kpis/:id', component: UserOverallKpisComponent }
    ]
  },
  { path: 'contact', component: ContactPageComponent },
  { path: 'privacy', component: PrivacyPageComponent },
  { path: 'press', component: PressPageComponent },
  { path: 'forgot', component: ForgotPasswordPageComponent },
  { path: 'reset', component: ResetPasswordPageComponent },
  { path: 'user/edit', component: UserEditPageComponent },
  { path: 'user/embed', component: UserEmbedPageComponent },
  { path: 'single', component: SingleFeedModalComponent },
  { path: 'edit/:id', component: EditCaseComponent },
  { path: 'forgot_password_form/:id', component: ForgotPasswordFormComponent },
  { path: 'change_password', component: ChangePasswordPageComponent },
  { path: 'change', component: ChangeCreditCardComponent },
  { path: 'legal', component: LegalComponent },
  { path: 'terms-of-use', component: TermsOfUseComponent },
  { path: 'terms-of-service', component: TermsOfServiceComponent },
  { path: 'watch-the-video', component: WatchTheVideoComponent }
  ];

@NgModule({
  declarations: [
    AppComponent,
    AppNavbarComponent,
    AppFooterComponent,
    SignupComponent,
    HomePageComponent,
    CasesComponent,
    SigninComponent,
    AdminComponent,
    NewsComponent,
    ContactPageComponent,
    PrivacyPageComponent,
    PressPageComponent,
    AddNewsComponent,
    EditNewsComponent,
    ContactPageComponent,
    ForgotPasswordPageComponent,
    ResetPasswordPageComponent,
    CreateCaseComponent,
    UserEditPageComponent,
    UserEmbedPageComponent,
    FeedComponent,
    SingleFeedModalComponent,
    SelectBrandsComponent,
    SelectTreatmentsComponent,
    SelectGenderComponent,
    SelectSkinTypeComponent,
    EditCaseComponent,
    EmbedPageComponent,
    GeneralFeedComponent,
    VisitorFeedComponent,
    AboutComponent,
    HeaderComponent,
    UnauthorizedComponent,
    ForgotPasswordFormComponent,
    ChangePasswordPageComponent,
    AdminCaseComponent,
    AdminCaseDetailsComponent,
    UsersComponent,
    EditUserComponent,
    AddUserComponent,
    TreatmentsComponent,
    EditTreatmentComponent,
    BrandsComponent,
    CreateEditBrandComponent,
    ManufacturersComponent,
    CreateEditManufacturerComponent,
    GendersComponent,
    CreateEditGenderComponent,
    SkinTypesComponent,
    CreateEditTypeComponent,
    EthnicitiesComponent,
    CreateEditEthnicityComponent,
    BannerComponent,
    ChangeCreditCardComponent,
    LegalComponent,
    TermsOfUseComponent,
    TermsOfServiceComponent,
    WatchTheVideoComponent,
    OverallKpisComponent,
    UserOverallKpisComponent,
    EditImageComponent,
    DatepickerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    CarouselModule.forRoot(),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    InfiniteScrollModule,
    ClickOutsideModule,
    ImageCropperModule,
    NgxWebstorageModule.forRoot(),
    ScrollToModule.forRoot(),
    ToastrModule.forRoot(),
    RouterModule.forRoot(
      appRoutes,
      // { enableTracing: true } // <-- debugging purposes only
    ),
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger',
      cancelButtonType: 'success'
    }),
    InfiniteScrollModule,
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    TabsModule.forRoot()
  ],
  providers: [
    CaseService,
    AuthService,
    Constants,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerilizer
    },
    NewsDataService,
    UserService,
    DataService,
    FeedDataService,
    HomePageService,
    AdminService,
    DatePipe
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    SingleFeedModalComponent
  ]
})
export class AppModule { }

