import {Component, Input, OnInit} from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { DataService } from '../helpers/data-service';

@Component({
  selector: 'app-navbar',
  templateUrl: './app-navbar.component.html',
  styleUrls: ['./app-navbar.component.scss']
})
export class AppNavbarComponent implements OnInit {

  public hideRight = false;
  public isOpen = false;
  public currentUser;
  public profileDropdown = false;
  @Input() profile;
  public imageUrl;
  public userFirstName;
  public providerProfileUrl;
  public showPatientLink = false;
  public showPricingLink = false;

  constructor(public authService: AuthService, private router: Router, private dataService: DataService) {
    this.router.events.subscribe(path => {
      if (path['url'] === '/signup') {
        this.hideRight = true;
      }
    });
    if (this.authService.isAuthenticated()) {
      this.currentUser = this.authService.getCurrentUser();
    }
  }

  ngOnInit() {
    if (this.currentUser) {
      this.userFirstName = this.currentUser.first_name;
      this.dataService.changeName(this.userFirstName);
      this.currentUser.image_url ? this.imageUrl = this.currentUser.image_url :
                                  this.imageUrl = '../../../assets/images/profilePlaceholder.png';
      this.dataService.changeProfileImage(this.imageUrl);
      this.providerProfileUrl = this.currentUser.provider_profile_url;
      this.dataService.changeUrl(this.providerProfileUrl);
    } else {
      this.showPatientLink = true;
      this.showPricingLink = true;
    }

    this.dataService.currentImage.subscribe(
      imageUrl => {
        this.imageUrl = imageUrl;
      });
    this.dataService.currentName.subscribe(
      firstName => {
        this.userFirstName = firstName;
      });
    this.dataService.currentProviderUrl.subscribe(
      providerProfileUrl => {
        this.providerProfileUrl = providerProfileUrl;
      });
  }

  onLogout() {
    this.authService.logout();
  }

  responsiveClass() {
    if (this.profile) {
      return this.isOpen ? 'topnav profile responsive' : 'topnav profile';
    } else {
      return this.isOpen ? 'topnav responsive' : 'topnav';

    }
  }

  showDropdown() {
    this.profileDropdown = !this.profileDropdown;
  }

  menuClicked() {
    this.isOpen = !this.isOpen;
    this.responsiveClass();
  }

  onClickedOutside(e: Event) {
    if (e['toElement'].id !== 'profile-dropown' && e['toElement'].id !== 'profile-button') {
      this.profileDropdown = false;
    }
    if (e['toElement'].id === 'profile-img') {
      this.profileDropdown = !this.profileDropdown;
    }
  }
}
