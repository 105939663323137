import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CaseService } from '../cases.service';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, ViewEncapsulation, ElementRef, ChangeDetectorRef, HostListener } from '@angular/core';
import { CaseModel } from '../case-model';
import { NavigationEnd, Router } from '@angular/router';
import { Constants } from '../../constants';

import { AuthService } from '../../auth/auth.service';
import { DataService } from '../../helpers/data-service';
declare let ga: any;

@Component({
  selector: 'app-create-case',
  templateUrl: './create-case.component.html',
  styleUrls: ['./create-case.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CreateCaseComponent implements OnInit {

  public case: CaseModel;
  public skinTypes;
  form: FormGroup;
  public formGender: FormControl;
  public formEthnicity: FormControl;
  public formAge: FormControl;
  public formDescription: FormControl;
  public selectedBrands = [];
  public selectedTreatments = [];
  public query;
  public treatments;
  public brands;
  public brand = '';
  public filteredList = [];
  public filteredTreatmentList = [];
  public genders;
  public angleNumber = 1;
  public treatmentItems = [{
    originalImageUrl: '',
    croppedImageUrl: '',
    watermarkedImageUrl: '',
    thumbnailUrl: '',
    buffer: null,
    treatmentName: 'Treatment 0',
    index: 0,
    angleNumber: this.angleNumber++
  }];
  public angles = [
    this.treatmentItems
  ];
  public selectedSkinType;
  public ethnicities;
  public ageList;
  public age;
  public validationError = false;
  public errorMessage = '';
  public treatmentListError = false;
  public brandListError = false;
  public skinTypeError = false;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  showCropper = false;
  data: any;
  index = null;
  angleIndex = 0;
  loading = false;
  buffers = [];
  public formGenderError = false;
  public formAgeError = false;
  public formDescriptionError = false;
  public agreedTerms = false;
  public uploadingCase = false;
  currentUser;
  public formSubmitted;
  public treatmentNumberDropdown;
  public usedNumbers = [100];
  public usedTreatmentNumbers;
  public selectedChosenNumber;
  public selectedUpdateNumber;
  public oldNumbers;
  originalImage: any = '';
  public isUploadButtonDisabled = false;
  tabSelected = 0;
  public pageYOffset = 0;
  public imagesUploadedCount = 0;
  public privateTooltip = false;
  public publicTooltip = false;
  public draftTooltip = false;



  constructor(private ref: ChangeDetectorRef, private _caseService: CaseService, private toastr: ToastrService, private router: Router,
    private constants: Constants, private elRef: ElementRef, private _authService: AuthService,
    private _dataService: DataService) {
    this.age = constants.age;
    this.treatmentNumberDropdown = constants.treatmentNumber.map(function (item) {
      return {
        number: item.number,
      };
    });
    this.data = {};

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        ga('set', 'page', event['urlAfterRedirects']);
        ga('send', 'pageview');
      }
    });
  }

  ngOnInit() {
    this.currentUser = this._authService.getCurrentUser();
    if (!this.currentUser) {
      this.router.navigate(['/signin/create']);
      return;
    }
    this.createFormControls();
    this.createForm();
    this.ageList = this.age;
    this.case = new CaseModel();
    this.case.patient_age = '0';
    this.formSubmitted = false;
    this._caseService.getTreatments(false).subscribe(
      data => { this.treatments = data; },
      err => this.toastr.error('Error loading treatments.')
    );
    this._caseService.getBrands(false).subscribe(
      data => { this.brands = data; },
      err => this.toastr.error('Error loading brands.')
    );
    this._caseService.getGenders(false).subscribe(
      data => { this.genders = data; },
      err => this.toastr.error('Error loading genders.')
    );
    this._caseService.getEthnicities().subscribe(
      data => { this.ethnicities = data; },
      err => this.toastr.error('Error loading ethnicities.')
    );
    this._caseService.getSkinTypes(false).subscribe(
      data => { this.skinTypes = data; },
      err => this.toastr.error('Error loading skin types.')
    );
    this.usedNumbers = [];
  }

  createFormControls() {
    this.formGender = new FormControl('', Validators.required);
    this.formEthnicity = new FormControl('');
    this.formAge = new FormControl('', Validators.required);
    this.formDescription = new FormControl('', Validators.required);
  }

  createForm() {
    this.form = new FormGroup({
      formGender: this.formGender,
      formEthnicity: this.formEthnicity,
      formAge: this.formAge,
      formDescription: this.formDescription
    });
  }
  onClickedOutside(e: Event) {
    if (e['toElement'].id !== 'treatment' && e['toElement'].id !== 'brand' &&
      e['toElement'].id !== 'skinType' && e['toElement'].id !== 'skin-type-span' &&
      e['toElement'].id !== 'skin-type-span2' && e['toElement'].id !== 'select-skin' &&
      e['toElement'].id !== 'select-skin-color') {
      this.filteredTreatmentList = [];
      this.filteredList = [];
    }
  }
  validateForm(form) {
    this.angles.forEach(angle => {
      angle.length < 2 || (angle.length === 2 && angle[1].originalImageUrl === '') ?
        (this.validationError = true, this.errorMessage = 'Create at least two treatments') : this.validationError = false;
    });
    this.selectedTreatments.length < 1 ? this.treatmentListError = true : this.treatmentListError = false;
    this.selectedBrands.length < 1 ? this.brandListError = true : this.brandListError = false;
    this.selectedSkinType ? this.skinTypeError = false : this.skinTypeError = true;
  }
  draftValidation() {
    (this.angles[0].length < 1 || this.angles[0][0].originalImageUrl === '') ?
      (this.validationError = true, this.errorMessage = 'Create at least one treatment') : this.validationError = false;
  }

  cancelCreate() {
    // TODO: this should go to profile, when we get profile page...
    this.router.navigate(['/']);
    this.imageChangedEvent.target.value = null;
  }

  getOlClass() {
    if (this.treatmentItems.length > 0) {
      return 'display';
    } else {
      return 'display-none';
    }
  }

  setImageIconClass(index, angleIndex) {
    if (this.angles[angleIndex][index].croppedImageUrl === '') {
      return 'uploaded-icon-hidden';
    } else {
      return 'uploaded-icon-visible';
    }
  }

  getEmptyItem(index) {
    return {
      originalImageUrl: '',
      croppedImageUrl: '',
      watermarkedImageUrl: '',
      thumbnailUrl: '',
      buffer: null,
      treatmentName: 'Treatment 0',
      index: index,
      angleNumber: 1,
    };
  }

  addItem(angleIndex) {
    const treatmentItemsLastIndex = this.angles[angleIndex].length - 1;
    if (this.angles[angleIndex][treatmentItemsLastIndex].croppedImageUrl === '' ||
      this.angles[angleIndex][treatmentItemsLastIndex].treatmentName.split(' ')[1] === '') {
      const message = treatmentItemsLastIndex === 0 ?
        'You need to upload the image for \'Before\' treatment first' :
        'You need to upload the image and set the treatment number for previous treatment first!';
      this.toastr.error(message);
    } else {
      const item = this.getEmptyItem(this.angles[angleIndex].length);
      this.angles[angleIndex].push(item);
    }
    this.angles[angleIndex].length < 2 ? (this.validationError = true, this.errorMessage = 'Create at least two treatments')
      : this.validationError = false;
  }

  chosenNumber(numb, angleIndex, i) {
    numb = numb.toString();
    this.usedNumbers.push(+numb);
    this.selectedChosenNumber = this.treatmentNumberDropdown.find(x => x.number.toString() === numb);
    const treatment = this.angles[angleIndex].find(x => x.index === i);
    treatment.treatmentName = 'Treatment ' + numb;
    this.updateDropdown();
  }

  updateDropdown() {
    this.usedTreatmentNumbers = [];
    this.treatmentItems.forEach(item => {
      this.usedTreatmentNumbers.push(+item.treatmentName.split(' ')[1]);
    });
    this.oldNumbers = this.usedNumbers.filter(item => this.usedTreatmentNumbers.indexOf(item) < 0);
    if (this.oldNumbers.length > 0) {
      this.oldNumbers.forEach(item => {
        this.selectedUpdateNumber = this.treatmentNumberDropdown.find(x => x.number === item);
        this.selectedUpdateNumber.isDisabled = false;
      });
    }
  }

  deleteItem(index, angleIndex) {
    const item = this.angles[angleIndex][index];
    const treatmentNumber = this.treatmentNumberDropdown.find(x => x.number.toString() === item.treatmentName.split(' ')[1]);
    const images = [
      item.originalImageUrl = item.originalImageUrl.split('/')[4],
      item.thumbnailUrl = 'thumb/' + item.thumbnailUrl.split('/')[5],
      item.watermarkedImageUrl = item.watermarkedImageUrl.split('/')[4],
      item.croppedImageUrl = item.croppedImageUrl.split('/')[4]
    ];
    this.angles[angleIndex].splice(index, 1);
    if (treatmentNumber !== undefined) {
      treatmentNumber.isDisabled = false;
      this.angles[angleIndex].splice(index, 0, this.getEmptyItem(index));
    } else {
      this.angles[angleIndex].splice(index, 0, this.getEmptyItem(index));
    }
    this._caseService.deleteImages(images, this.currentUser.id).subscribe(
      data => this.toastr.error('Successfully deleted'),
      err => { }
    );
  }


  selectSkinType(skinType) {
    this.selectedSkinType = skinType;
    this.case.skin_type_id = skinType.id;
    this.skinTypeError = false;
  }

  filter() {
    this.filteredList = this.brands
      .filter((element) =>
        element.brands.some((brand) => brand.name.toLowerCase().indexOf(this.brand.toLowerCase()) > -1));
  }

  onGenderSelect(genderId) {
    this.case.gender_id = Number(genderId);
  }

  onEthnicitySelect(ethnicityId) {
    this.case.ethnicity_id = ethnicityId;
  }

  mapLists() {
    this.case.treatment_ids = this.selectedTreatments.map(treatment => treatment.id);
    this.case.brand_ids = this.selectedBrands.map(brand => brand.id);
    this.case.case_images_attributes = [];

    this.angles.forEach(angle => {
      angle.forEach(item => {
        if (item.originalImageUrl !== '') {
          const caseItem = {
            treatment_name: item.treatmentName,
            original_image_url: item.originalImageUrl,
            cropped_image_url: item.croppedImageUrl,
            cropped_watermark_image_url: item.watermarkedImageUrl,
            thumbnail_url: item.thumbnailUrl,
            angle_number: item.angleNumber
          };
          this.case.case_images_attributes.push(caseItem);
        }
      });
    });

    this.treatmentItems.map(function (item) {
      if (item.originalImageUrl !== '') {
        return {
          treatment_name: item.treatmentName,
          original_image_url: item.originalImageUrl,
          cropped_image_url: item.croppedImageUrl,
          cropped_watermark_image_url: item.watermarkedImageUrl,
          thumbnail_url: item.thumbnailUrl,
          angle_number: 1
        };
      }
    });
    this.case.patient_age = this.form.value.formAge;
  }

  async uploadCroppedImage() {
    await this.uploadOriginal();
    this.showCropper = false;
  }


  uploadOriginal() {
    console.log('[' + new Date().toUTCString() + '] ' + ' uploading Original Image');
    const originalImage = this.originalImage ? this.originalImage : this.imageChangedEvent.target.files[0];
    const hashedNameOriginal = this.originalImage ? this._dataService.getUuid(originalImage.file.type.split('/')[1]) :
      this._dataService.getUuid(originalImage.type.split('/')[1]);
    const original = {
      name: hashedNameOriginal,
      type: originalImage.file.type
    };

    this.loading = true;
    this.imagesUploadedCount++;
    this._caseService.getPreSignedUrl(original, this.currentUser.id).subscribe(
      data => {
        const dataURL = data['url'];
        const originalImageUrl = dataURL.substr(0, dataURL.indexOf('?'));
        this.angles[this.angleIndex][this.index].originalImageUrl = originalImageUrl;

        const OriginalFileToUpload = this.imageChangedEvent.target.files[0];
        this._caseService.uploadFile(dataURL, OriginalFileToUpload).subscribe(
          s3data => {
            console.log('[' + new Date().toUTCString() + '] ' + ' Finish uploading Original Image');
            console.log('[' + new Date().toUTCString() + '] ' + 'Start image process ');
            const params = {
              x: this.originalImage.cropObj.left.toString(),
              y: this.originalImage.cropObj.top.toString(),
              w: this.originalImage.cropObj.width.toString(),
              h: this.originalImage.cropObj.height.toString(),
              filename: hashedNameOriginal,
              caseAction: 'create',
              blackBars: []
            };
            if (originalImage.blackBars && originalImage.blackBars.length) {
              for (let i = 0; i < originalImage.blackBars.length; i++) {
                let blackBarsValues = {};
                const origWithValue = originalImage.blackBars[i].width * originalImage.cropRatio;
                const origCoordXValue = (originalImage.blackBars[i].startX * originalImage.cropRatio);
                const coordXValue = (origWithValue < 0) ? origCoordXValue - Math.abs(origWithValue) : origCoordXValue;
                const origHeightValue = originalImage.blackBars[i].height * originalImage.cropRatio;
                const origCoordYValue = (originalImage.blackBars[i].startY * originalImage.cropRatio);
                const coordYValue = (origHeightValue < 0) ? origCoordYValue - Math.abs(origHeightValue) : origCoordYValue;
                blackBarsValues = {
                  bbWidth: Math.abs(origWithValue).toString(),
                  bbHeight: Math.abs(origHeightValue).toString(),
                  bbX: coordXValue.toString(),
                  bbY: coordYValue.toString()
                };
                params.blackBars.push(blackBarsValues);
              }
            }
            console.log('params', params);

            this._caseService.resizerImage(this.currentUser.id, params).subscribe(
              (response: any) => {
                console.log('[' + new Date().toUTCString() + '] ' + 'finish image process');
                this.loading = false;

                const urlPath = dataURL.substr(0, originalImageUrl.lastIndexOf('/')) + '/';
                const croppedImageUrl = urlPath + response.watermarkFilename;
                const watermarkedImageUrl = urlPath + response.watermarkFilename;
                const thumbImageUrl = urlPath + response.thumbFolderPath;

                const image = this.originalImage.base64;

                const imageUploadPosition = this.imagesUploadedCount;
                this.angles[this.angleIndex][this.index].buffer = image;
                const angleIndex = this.angleIndex;
                const index = this.index;
                const angleNumberStored = this.angleIndex + 1;

                this.angles[angleIndex][index].croppedImageUrl = croppedImageUrl;
                this.angles[angleIndex][index].watermarkedImageUrl = watermarkedImageUrl;
                this.angles[angleIndex][index].thumbnailUrl = thumbImageUrl;
                this.angles[angleIndex][index].angleNumber = angleNumberStored;
                if (imageUploadPosition === this.imagesUploadedCount) {
                  this.loading = false;
                }
                if (this.angles[angleIndex][index].index !== 0 && this.angles[angleIndex][index].treatmentName === 'Treatment 0') {
                  this.angles[angleIndex][index].treatmentName = 'Treatment 1';
                }

              },
              error => {
                console.log('ERROR', error);
              }
            );


            return originalImageUrl;
          }
        );
      },
      err => {
      }
    );
  }

  fileChangeEvent(event: any, index): void {
    const file = event.target.files[0];
    this.validationError = false;
    if (file.size <= 15000000) {
      if (this._dataService.checkUploadedFileType(file.type)) {
        this.showCropper = true;
        this.imageChangedEvent = event;
        this.index = index;
      } else {
        this.toastr.error('Please upload allowed file types: .jpeg, .jpg or .png.');
      }
    } else {
      this.validationError = true;
      this.errorMessage = 'File is too large.  Please choose a file under 15MB';
    }
  }

  cancelUpload() {
    this.showCropper = false;
    this.imageChangedEvent.target.value = null;
    this.originalImage = null;
  }

  imageCropped(image: string) {
    this.croppedImage = image;
  }

  imageOriginal(image: string) {
    this.originalImage = image;
    this.isUploadButtonDisabled = false;
  }

  disableUploadButton(disabled) {
    this.isUploadButtonDisabled = disabled;
  }


  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    this.pageYOffset = window.pageYOffset;
  }


  onSubmit(draft: boolean, publicForAll: boolean) {
    this.case.is_draft = draft;
    this.case.is_deleted = false;
    this.case.is_public = publicForAll;
    const reqCase = {
      case: this.case
    };
    if (draft) {
      this.validationError = false;
      this.draftValidation();
      if (!this.validationError) {
        this.uploadingCase = true;
        this.mapLists();
        this._caseService.addNewCase(reqCase).subscribe(
          data => {
            this.uploadingCase = false;
            this.router.navigate(['/hm/', this.currentUser.id]);
            this.toastr.success('Saved as draft');
          },
          error => {
            this.toastr.error('Unable to save draft');
          }
        );
      } else {
        this.toastr.error('Please check required fields');
      }
    } else {
      this.validationError = false;
      this.validateForm(this.form);
      this.formSubmitted = true;
      if (!this.agreedTerms) {
        this.toastr.error('Please agree to the Terms and Conditions.');
      } else if (!draft && this.form.valid && !this.brandListError && !this.validationError &&
        !this.skinTypeError && !this.treatmentListError && this.agreedTerms) {
        this.uploadingCase = true;
        this.mapLists();
        this._caseService.addNewCase(reqCase).subscribe(
          data => {
            this.uploadingCase = false;
            this.router.navigate(['/hm/', this.currentUser.id]);
            this.toastr.success('Case uploaded');
          },
          error => {
            this.toastr.error('Unable to upload case');
          }
        );
      } else {
        this.formGenderError = this.formGender.value === '';
        this.formAgeError = this.formAge.value === '';
        this.formDescriptionError = this.formDescription.value === undefined;
        this.toastr.error('Please check all required fields.');
      }
    }
  }
  checkTreatmentsLength() {
    this.treatmentListError = this.selectedTreatments.length < 1;
  }
  checkBrandsLength() {
    this.brandListError = this.selectedBrands.length < 1;
  }
  agree(agreed) {
    this.agreedTerms = !agreed;
  }

  // TODO: Create a new tabs component

  selectTab(tab) {
    this.tabSelected = tab;
    this.angleIndex = tab;
  }


  addAngle() {
    const newAngle = [{
      originalImageUrl: '',
      croppedImageUrl: '',
      watermarkedImageUrl: '',
      thumbnailUrl: '',
      buffer: null,
      treatmentName: 'Treatment 0',
      index: 0,
      angleNumber: 0
    }];

    if (this.angles.length < 5) {
      this.angles.push(newAngle);
      this.tabSelected = this.angles.length - 1;
      this.angleIndex = this.tabSelected;
    }
  }

  removeAngle(angleIndex) {
    this.angles.splice(angleIndex, 1);
    this.tabSelected = angleIndex < this.angles.length ? angleIndex : angleIndex - 1;
    this.angleIndex = angleIndex < this.angles.length ? angleIndex : angleIndex - 1;
  }
}
