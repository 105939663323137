import {Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {CaseService} from '../../cases/cases.service';

@Component({
  selector: 'app-select-treatments',
  templateUrl: './select-treatments.component.html',
  styleUrls: ['../select-items.scss']
})
export class SelectTreatmentsComponent implements OnInit, OnChanges {

  @Input() selectedTreatments;
  @Input() userId;
  @Input() home;
  @Output() countChanged: EventEmitter<string> = new EventEmitter();
  @Input() loadFilterOnFirstClick;
  @Input() treatmentRemoved;

  treatments;
  filteredList = [];
  treatment = '';
  placeholder;
  constructor(private _caseService: CaseService) { }

  ngOnInit() {
    const userID = this.userId || false;
    this._caseService.getTreatments(userID).subscribe(
      data => {
        this.treatments = data;
        this.treatments.forEach(item => {
          item.is_visible = true;
        });
        if (this.selectedTreatments) {
          this.hideSelectedTreatments();
        }
        if (this.loadFilterOnFirstClick) {
          this.filteredList = this.treatments;
        }
        },
      err => {},
      () => {}
    );
    this.placeholder = this.home ? 'Any treatment' : 'Type...';
  }

  getWrapClass() {
    return this.home ? 'wrap-landing' : 'wrap';
  }

  getDevices() {
    this.filteredList = this.treatments;
  }

  public remove(item) {
    this.selectedTreatments.splice(this.selectedTreatments.indexOf(item), 1);
    this.countChanged.emit('treatment');
    const unSelectedTreatment = this.treatments.find(x => x.id === item.id);
    unSelectedTreatment.is_visible = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedTreatments'] && changes['selectedTreatments'].currentValue && changes['selectedTreatments'].currentValue.length === 0 &&
      changes['selectedTreatments'].previousValue && changes['selectedTreatments'].previousValue.length > 0) {

      changes['selectedTreatments'].previousValue.forEach(item => {
        this.treatments.find(x => x.id === item.id).is_visible = true;
      });
    }
    if (changes['treatmentRemoved'] && !changes['treatmentRemoved'].firstChange && changes['treatmentRemoved'].currentValue) {
      this.treatments.find(x => x.id === changes['treatmentRemoved'].currentValue.id).is_visible = true;
    }
  }

  select(item) {
    this.selectedTreatments.push(item);
    this.countChanged.emit('treatment');
    this.treatment = '';
    this.filteredList = [];
    item.is_visible = false;
  }

  hideSelectedTreatments() {
    this.selectedTreatments.forEach(item => {
      if (!item.is_deleted) {
        const selectedTreatment = this.treatments.find(x => x.id === item.id);
        selectedTreatment.is_visible = false;
      }
    });
  }

  filter() {
    if (this.treatment !== '') {
      this.filteredList = this.treatments.filter(function(el) {
        return el.name.toLowerCase().indexOf(this.treatment.toLowerCase()) > -1;
      }.bind(this));
    } else {
      this.filteredList = [];
    }
  }

  getBrandClass() {
    if (this.selectedTreatments.length === 0) {
      return 'input-field';
    } else {
      return 'input-field-selected';
    }
  }

  onClickedOutside(e: Event) {
    if (e['toElement'].id !== 'treatment') {
      this.filteredList = [];
    }
  }
}
