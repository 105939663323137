export class FilterModel {
  gender: string;
  skin_type: string;
  age: string;
  treatments: Array<number> = [];
  brands: Array<number> = [];
  manufacturer_name: string;
  brand_name: string;
  treatment_name: string;
  user_id: number;
  is_draft: boolean;
  public: boolean;
  private: boolean;
  case_id: number;
}
